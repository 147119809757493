export default [
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/product/product-list/ProductList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
];
