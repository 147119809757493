export default [
    {
      path: "/retrocession",
      name: "retrocession-list",
      component: () => import("@/views/retrocession/retrocession-list/RetrocessionList.vue"),
      meta: {
        action: "read",
        resource: "retrocession",
      },
    },
    {
      path: "/retrocession/create",
      name: "retrocession-create",
      component: () => import("@/views/retrocession/retrocession-edit/retrocessionEdit.vue"),
      meta: {
        action: "create",
        resource: "retrocession",
      },
    },
    {
      path: "/retrocession/edit/:id",
      name: "retrocession-edit",
      component: () => import("@/views/retrocession/retrocession-edit/retrocessionEdit.vue"),
      meta: {
        action: "update",
        resource: "retrocession",
      },
    },
  ];