export default [
  {
    path: "/staff-roles/list",
    name: "staff-roles-list",
    component: () => import("@/views/role/roles-list/StaffRolesList.vue"),
    meta: {
      action: "read",
      resource: "permissions",
    },
  },
  {
    path: "/roles/edit/:id",
    name: "roles-edit",
    component: () => import("@/views/role/roles-edit/RolesEdit.vue"),
    meta: {
      action: "read",
      resource: "permissions",
    },
  },
  {
    path: "/roles/list",
    name: "roles-list",
    component: () => import("@/views/role/roles-list/RolesList.vue"),
    meta: {
      action: "read",
      resource: "permissions",
    },
  },
  {
    path: "/roles/permission/edit/:id",
    name: "roles-permission-edit",
    component: () => import("@/views/role/roles-edit/RolesPermissionEdit.vue"),
    meta: {
      action: "read",
      resource: "permissions",
    },
  },
];
