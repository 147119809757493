export default [
  {
    path: "/banks",
    name: "bank-list",
    component: () => import("@/views/bank/banks-list/BanksList.vue"),
    meta: {
      action: "read",
      resource: "bank",
    },
  },
  {
    path: "/banks/RMs",
    name: "bank-detail-list",
    component: () => import("@/views/bank/banks-list/BanksDetailList.vue"),
    meta: {
      action: "read",
      resource: "bank",
    },
  },
  {
    path: "/banks/create",
    name: "banks-create",
    component: () => import("@/views/bank/banks-edit/BanksEdit.vue"),
    meta: {
      action: "create",
      resource: "bank",
    },
  },
  {
    path: "/banks/edit/:id",
    name: "banks-edit",
    component: () => import("@/views/bank/banks-edit/BanksEdit.vue"),
    meta: {
      action: "update",
      resource: "bank",
    },
  },
  {
      path: "/banks/detail/:id",
      name: "banks-detail",
      component: () => import("@/views/bank/banks-detail/BanksDetail.vue"),
      meta:{
          action:"read",
          resource:"bank"
      }
  }
];
