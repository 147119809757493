export default [
    {
        path: "/account",
        name: "account-list",
        component: () => import("@/views/clientuser/clientuser-list/ClientUserList.vue"),
        meta:{
            action: "read",
            resource: "client_user",
        }
    },
    {
        path: "/account/detail/:id",
        name: "account-detail",
        component: () => import("@/views/clientuser/clientuser-detail/ClientUserDetail.vue"),
        meta:{
            action: "read",
            resource: "client_user",
        }
    },
]