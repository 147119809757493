export default [
    {
      path: "/orders-list",
      name: "order-list",
      component: () => import("@/views/order/orders-list/OrdersList.vue"),
      meta: {
        resource: "Auth",
        action: "read",
      },
    },
    {
        path: "/orders/edit/:id",
        name: "orders-edit",
        component: () => import("@/views/order/orders-edit/OrdersEdit.vue"),
        meta: {
          action: "read", // change back to update
          resource: "Auth",
        },
      },
      {
        path: "/orders/create",
        name: "orders-create",
        component: () => import("@/views/order/orders-edit/OrdersEdit.vue"),
        meta: {
          action: "read", // change back to create
          resource: "Auth",
        },
      },
  ];
  