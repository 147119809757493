export default [
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/",
    name: "login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot",
    name: "auth-forgot-password-v2",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v2.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  
  {
    path: "/reset-password",
    name: "auth-reset-password",
    component: () =>
      import("@/views/pages/authentication/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      action: 'read',
      resource: "dashboard",
    },
  }, 
   
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "/pages/not-authorized",
    name: "not-authorized",
    // ! Update import path
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      action: "read",
      resource: "Auth",
    },
  },
];
