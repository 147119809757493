export default [
    {
        path: "/documents/staff",
        name: "document-staff-list",
        component: () => import("@/views/document/documents-list/DocumentsList.vue"),
        meta:{
            action: "read",
            resource: "documents",
        }
    },
    {
        path: "/documents/client",
        name: "document-client-list",
        component: () => import("@/views/document/documents-list/DocumentsList.vue"),
        meta:{
            action: "read",
            resource: "documents",
        }
    },
    {
        path: "/documents/staff/upload",
        name: "document-staff-upload",
        component: () => import("@/views/document/documents-upload/DocumentsUpload.vue"),
        meta:{
            action: "create",
            resource: "documents"
        }
    },
    {
        path: "/documents/client/upload",
        name: "document-client-upload",
        component: () => import("@/views/document/documents-upload/DocumentsUpload.vue"),
        meta:{
            action: "create",
            resource: "documents"
        }
    }
]