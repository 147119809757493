export default [
  {
    path: "/users/list",
    name: "users-list",
    component: () => import("@/views/user/users-list/UsersList.vue"),
    meta: {
      action: "read",
      resource: "user",
    },
  },
  {
    path: "/users/view/:id",
    name: "users-view",
    component: () => import("@/views/user/users-view/UsersView.vue"),
    meta: {
      action: "read",
      resource: "user",
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "read", // change back to update
      resource: "user",
    },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "read", // change back to create
      resource: "user",
    },
  },
  {
    path: "/users/course/list",
    name: "users-training-record",
    component: () => import("@/views/user/users-course/UsersCourseList.vue"),
    meta: {
      action: "read", // change back to create
      resource: "course",
    },
  },
  {
    path: "/users/course/:id",
    name: "users-training-record-edit",
    component: () => import("@/views/user/users-course/EditUserCourse.vue"),
    meta: {
      action: "update", // change back to update
      resource: "course",
    },
  },


  // *===============================================---*
  // *--------- TEAM ---------------------------------------*
  // *===============================================---*

  {
    path:'/teams/list',
    name: 'teams-list',
    component:() => import('@/views/team/teams-list/TeamsList.vue'),
    meta:{
      action: 'read',
      resource: 'team'
    }
  },
  {
    path:'/teams/create',
    name: 'teams-create',
    component:() => import('@/views/team/teams-edit/TeamsEdit.vue'),
    meta:{
      action: 'read',
      resource: 'team'
    }
  },
  {
    path:'/teams/edit/:id',
    name:'teams-edit',
    component: () => import('@/views/team/teams-edit/TeamsEdit.vue'),
    meta:{
      action: 'read',
      resource: 'team'
    }
  },
];
